
  import Logo from '@/components/Logo.vue';
  import { defineComponent } from 'vue';
  import ScreenSettings from '@/components/settings/ScreenSettings.vue';
  import ExpandedSettings from '@/components/settings/ExpandedSettings.vue';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';
  import { closeDrawer, $drawer } from '@/store/drawer';
  import ChangeLayout from '@/components/ChangeLayout.vue';
  import Drawer from '@/components/Drawer.vue';
  import MenuSettings from '@/components/settings/MenuSettings.vue';
  import MobileExpandedSettings from '@/components/settings/MobileExpandedSettings.vue';
  import MobileDownloadFile from '@/components/settings/MobileDownloadFile.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { Popup, Button } from '@voximplant/spaceui';
  import Assessment from '@/components/feedback/Assessment.vue';
  import Feedback from '@/components/feedback/Feedback.vue';
  import { isPopupOpened, togglePopup } from '@/store/popup';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'SettingsInDefault',
    components: {
      Logo,
      ScreenSettings,
      ExpandedSettings,
      ChangeLayout,
      Drawer,
      MenuSettings,
      MobileExpandedSettings,
      MobileDownloadFile,
      Popup,
      Button,
      Assessment,
      Feedback,
    },
    props: {
      conference: {
        type: String,
        default: '',
        required: true,
      },
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const drawerStore = useStore($drawer);
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const allowDevicesImg = require('@/assets/images/allowDevicesImg.jpg');
      const popupOpened = useStore(isPopupOpened);
      const closePopup = () => {
        togglePopup(false);
        closeDrawer();
      };

      return {
        popupOpened,
        closePopup,
        isMobileScreen,
        t,
        drawerStore,
        allowDevicesImg,
      };
    },
  });
