import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sign-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsInDefault = _resolveComponent("SettingsInDefault")
  const _component_WrongConferenceIDPopUp = _resolveComponent("WrongConferenceIDPopUp")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.authS.authState === 1)
      ? (_openBlock(), _createBlock(_component_SettingsInDefault, {
          key: 0,
          deviceError: _ctx.requestResult,
          conference: _ctx.conference
        }, null, 8, ["deviceError", "conference"]))
      : _createCommentVNode("", true),
    (_ctx.isWrongConferenceID)
      ? (_openBlock(), _createBlock(_component_WrongConferenceIDPopUp, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}