
import {computed, defineComponent, onMounted, ref} from 'vue';
import {Button} from '@voximplant/spaceui';
import Info from '@/components/info/Info.vue';
import ButtonPanel from '@/components/settings/ButtonPanel.vue';
import SpeakerName from '@/components/settings/SpeakerName.vue';
import userVideoMock from '@/components/layouts/userVideoMock.vue';
import {$devices} from '@/store/devices/index';
import {useStore} from 'effector-vue/composition';
import {meetingStore, startMeeting} from '@/store/meeting';
import {authStore} from '@/store/auth';
import {$mirrorStore} from '@/store/mirrorMedia/index';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {$conferenceStore, DetailsState, ErrorCode} from "@/store/room";
import moment from "moment";

// Элемент где включается отключается видео и кнопка подключения
  export default defineComponent({
    name: 'ScreenSettings',
    components: {
      Button,
      Info,
      ButtonPanel,
      SpeakerName,
      userVideoMock,
    },

    props: {
      conference: {
        type: String,
        default: '',
        required: true,
      },
      isShow: {
        type: Boolean,
        default: true,
      },
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const mirrorVideo = ref<HTMLVideoElement>();
      // const meeting = useStore(meetingStore);
      const auth = useStore(authStore);
      const devices = useStore($devices);
      const { t } = useI18n();
      const mirror = useStore($mirrorStore);
      const conferenceDetailStore = useStore($conferenceStore)



      // TODO отформатировать вывод даты и времени
      const conferenceInfo = computed<{ description: string, start: string, end: string, now: string }| null>(() => {
        if(conferenceDetailStore.value.state !== DetailsState.NoInit && conferenceDetailStore.value.state !== DetailsState.Error){
          const d = conferenceDetailStore.value.details
          return {
            description: d.description,
            start: moment.utc(d.startAt).local().format('DD.MM.YYYY HH:mm (Z)'),
            end: d.endAt.toString(),
            now: new Date().toISOString(),
          } as { description: string, start: string, end: string, now: string }
        }
        return null
      })
      // toISOString() выводит в формате UTC

      const zeroPad = (num: number) => String(num).padStart(2, '0')
      const leftTimeInfo = computed<string|null>(()=>{
        if(conferenceDetailStore.value.state == DetailsState.Pending){
          const d = conferenceDetailStore.value.leftTime
          return `${zeroPad(d.hours)}:${zeroPad(d.minutes)}:${zeroPad(d.seconds)}`
        }
        else {
          return null
        }
      })

      const isEnded = computed<boolean>(() => {
          return conferenceDetailStore.value.state == DetailsState.Ended
      })

      const errorInfo = computed<string | null>(()=>{
        if(conferenceDetailStore.value.state == DetailsState.Error){
          if(conferenceDetailStore.value.code == ErrorCode.NotFound){
            return 'Конференция не найдена'
          }
          else return 'Неизвестная ошибка'
        }
        return null
      })

      const isJoinDisable = computed(() => {
        const res =
          (!!props.deviceError && props.deviceError !== 'noCamera') ||
          !mirror.value.audioPreview ||
          alreadyJoin.value || conferenceDetailStore.value.state !== DetailsState.Active;
        return res;
      });
      onMounted(() => {
        $mirrorStore.watch((state) => {
          if (state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = new MediaStream([state.videoPreview]);
            mirrorVideo.value?.play();
          }

          if (!state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = null;
          }
        });
      });

      const router = useRouter();
      const alreadyJoin = ref(false);
      const clickJoin = async () => {
        console.log('JOIN CLICKED');
        alreadyJoin.value = true;
        console.log('SETTINGS ROUTE');

        console.log(`SETTINGS CONFERENCE ID: ${props.conference}`);
        console.log('PROPS');
        console.log(props);
        const meetingId = props.conference;
        await startMeeting(meetingId);

        if (props.conference) {
          const conference = props.conference;
          await router.replace({
            name: 'VideoConference',
            params: {
              conference,
            },
          });
        }
      };

      return {
        t,
        isJoinDisable,
        clickJoin,
        auth,
        devices,
        mirrorVideo,
        conferenceInfo,
        status,
        leftTimeInfo,
        isEnded,
        errorInfo
      };
    },
  });
