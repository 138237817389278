
  import { computed, defineComponent } from 'vue';
  import { Input, Select, Button, Icon } from '@voximplant/spaceui';
  import DownloadedFiles from '@/components/decorative-elements/DownloadedFiles.vue';
  import FileInput from '@/components/inputs/FileInput.vue';
  import TogglePanel from '@/components/settings/TogglePanel.vue';
  import Sound from '@/components/decorative-elements/Sound.vue';
  import Tooltip from '@/components/info/Tooltip.vue';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { meetingStore } from '@/store/meeting';
  import { userAgent, Vendor } from '@/helpers/vendor';
  import {
    $devices,
    selectSpeakerDevice,
    setVideoQuality,
    toggleAudioEvent,
    toggleVideoEvent,
  } from '@/store/devices/index';
  import { requestMirrorStream } from '@/store/mirrorMedia/index';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';

  export default defineComponent({
    name: 'ExpandedSettings',
    components: {
      Tooltip,
      Input,
      Select,
      Button,
      Icon,
      DownloadedFiles,
      FileInput,
      TogglePanel,
      Sound,
    },
    props: {
      soundIcon: {
        type: String,
        default: require('@/assets/images/mocks/icon-sound.png'),
      },
      text: {
        type: String,
        default: 'Click Test Speaker to make sure you can hear others',
      },
      deviceError: {
        type: String,
        default: '',
        required: false,
      },
    },
    setup(props) {
      const devices = useStore($devices);
      const { t } = useI18n();
      const isAudioDisabled = computed(
        () => !!props.deviceError && props.deviceError !== 'noCamera'
      );
      const meeting = useStore(meetingStore);
      const updateAudioDevice = (e: AudioDeviceInfo) => {
        if (!devices.value.audioEnabled) toggleAudioEvent();
        requestMirrorStream({
          selectedAudioDevice: e,
        });
      };
      const updateSpeakerDevice = (e: AudioDeviceInfo) => {
        selectSpeakerDevice(e);
      };
      const updateVideoDevice = (e: VideoDeviceInfo) => {
        if (!devices.value.videoEnabled) toggleVideoEvent();
        requestMirrorStream({
          selectedVideoDevice: e,
        });
      };
      const updateVideoQuality = (e: Record<string, string>) => {
        setVideoQuality(e);
      };
      const canUseOutputDevice = () => {
        return userAgent === Vendor.chrome;
      };
      return {
        t,
        updateVideoQuality,
        updateAudioDevice,
        updateSpeakerDevice,
        updateVideoDevice,
        meeting,
        devices,
        isAudioDisabled,
        canUseOutputDevice,
      };
    },
  });
